import React, {Component} from 'react';
import {Router} from "@reach/router";

import Posts from './components/posts'
import Post from './components/post'

const BASE_STATE = {
    username: '',
    title: '',
    content: ''
};

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = BASE_STATE;
        this.onChange = this.onChange.bind(this);
        this.onSsubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        fetch("https://solitary-poetry-1cbc.balguliev.workers.dev/posts", {
            method: 'POST',
            headers: {
                'Accept': '*',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(this.state)
        }).then((response) => {
            if (response.ok) {
                alert("Post submitted!\n\nIt make take some time for the post to show up.");
            } else if (response.status === 401) {
                alert(`Post failed to submit.\n\nYou were not allowed access to the username "${this.state.username}".`);
            } else {
                alert("Post failed to submit for unknown reason.");
            }
        }).catch(() => alert("Failed to connect to backend."));

        this.setState(BASE_STATE);
    }

    render() {
        return (
            <center>
                <h1>Welcome to ReadIt</h1>
                <Router>
                    <Posts path="/"/>
                    <Post path="/posts/:id"/>
                </Router>
                <div>
                    Submit a post!
                    <form
                        id='submit-form'
                        action="https://solitary-poetry-1cbc.balguliev.workers.dev/posts"
                        method="POST"
                        onSubmit={this.onSubmit}>
                        <div>
                            <input name="username" id="username"
                                   onChange={this.onChange}
                                   placeholder='Username'/>
                        </div>
                        <div>
                            <input name="title" id="title"
                                   onChange={this.onChange}
                                   placeholder='Title'/>
                        </div>
                        <div>
                            <textarea name="content" id="content"
                                      onChange={this.onChange}
                                      placeholder='Content'/>
                        </div>
                        <div>
                            <button>Post</button>
                        </div>
                    </form>
                </div>
            </center>
        );
    }
}

